import React from "react"
import styled from "@emotion/styled"

const Welcome = styled.section`
  background: #000;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: column wrap;
  @media (min-width: 600px) {
    flex-flow: row wrap;
  }
  @media (min-width: 1200px) {
    flex-flow: row nowrap;
  }
`

const Item = styled.div`
  flex: 0 1 25%;
  padding: 1.5rem 1.5rem;
  border-bottom: 1px solid #555;
  strong {
    color: #fff;
  }
  h2 {
    text-align: center;
    color: #eee;
    font-weight: 700;
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }
  & > div > p {
    color: #ccc;
    font-size: 1rem;
  }
  @media (min-width: 600px) {
    flex: 0 1 50%;
    border-right: 1px solid #555;
  }
  @media (min-width: 1200px) {
    flex: 1;
    border-right: 1px solid #555;
    &:last-child {
      border-right: none;
    }
    h2 {
      font-size: 1rem;
    }
    & > div > p {
      font-size: 0.9rem;
    }
  }
  @media (min-width: 1440px) {
    h2 {
      font-size: 1.1rem;
    }
  }
  @media (min-width: 1920px) {
    h2 {
      font-size: 1.2rem;
    }
    & > div > p {
      font-size: 1rem;
    }
  }
`

const Advantages = ({ content }) => {
  const items = content.map(item => (
    <Item key={item.id}>
      <h2>{item.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: item.description }} />
    </Item>
  ))

  return <Welcome>{items}</Welcome>
}

export default Advantages
