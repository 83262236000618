/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import styled from "@emotion/styled"
import Cta from "../../elements/Cta"
import IconPhone from "../icons/icon-phone"

const FormWrapper = styled.div`
  width: 100%;
  padding: 2rem 1rem;
  background: #eaeaea;
  position: relative;
  z-index: 1;

  & > .blockForm {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 2;

    & > .warn {
      position: relative;
      display: flex;
      justify-content: center;
      z-index: 5;
      width: 100%;
      height: 100%;

      & > .content {
        margin-top: 2rem;
        background: #0d6efd;
        border-radius: 5px;
        padding: 1rem;
        width: 90%;
        max-width: 420px;
        max-height: 270px;

        & > h2 {
          font-size: 1.2rem;
          margin-bottom: 1.5rem;
          font-weight: 700;
          text-align: center;
          text-transform: uppercase;
          color: white;
        }

        & > p {
          font-size: 1rem;
          margin-bottom: 1rem;
          color: white;
        }

        & > a {
          margin: 2rem auto 0 auto;
        }
      }
    }
  }

  & > form {
    display: flex;
    flex-flow: column wrap;

    & > p {
      flex: 1 0 100%;
      font-weight: bold;
      font-size: 1.1rem;
      margin-bottom: 1rem;
    }

    & > .form-control {
      margin: 0 0 1rem 0;
      display: flex;
      flex-flow: column wrap;

      & > label {
        margin: 0 0 0.2rem 0;
        font-weight: bold;
      }

      & > p.error {
        color: red;
        padding: 0.5rem 0 0 0.5rem;
      }

      & > input,
      & > textarea,
      & > select {
        box-sizing: border-box;
        background: white;
        color: grey;
        font-weight: light;
        font-size: 0.9rem;
        border-radius: 3px;
        width: 100%;
        appearance: none;
        border-width: 1px;
        border-style: solid;
        border-color: #b2bec3;
        padding: 10px;
        &:focus {
          border-color: #b2bec3;
          box-shadow: 0 0 0 2px #44bd32;
          outline: none;
        }
      }

      & > input,
      & > select {
        max-height: 40px;
      }

      & > select.pasajeros {
        margin-bottom: 2rem;
      }
    }
    @media (min-width: 600px) {
      flex-flow: row wrap;
      justify-content: space-between;
      max-width: 768px;
      margin: 0 auto;

      & > .form-control {
        flex: 0 1 48%;
      }
      & > .origen {
        flex: 0 1 60%;
      }
      & > .poblacion {
        flex: 0 1 35%;
      }
      & > .menor {
        flex: 0 1 30%;
      }
      & > .textarea {
        flex: 1 0 100%;
      }
    }
    @media (min-width: 1200px) {
      max-width: 1024px;
    }
  }
`
const SubmitWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem 0;

  & > button {
    background: #000;
    color: #fff;
    border-radius: 90px;
    padding: 0.5rem 1rem;
    border-width: 3px;
    border-style: solid;
    border-color: #000;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    min-width: 160px;
    &:hover {
      transition: all 0.5s;
      background: #fff;
      color: #000;
    }
  }
`

// const phoneExp = /^\d{9}$/

function addDay() {
  let date = new Date()
  date.setDate(date.getDate() - 1)
  date.toLocaleDateString("es-ES")
  return date
}

const BookingForm = () => {
  return (
    <FormWrapper>
      <section className="blockForm">
        <div className="warn">
          <div className="content">
            <h2>Lo sentimos</h2>
            <p>Estamos modificando el sistema de reservas online.</p>
            <p>Puede realizar su reserva anticipada por teléfono.</p>
            <Cta
              title="Llámanos"
              href="tel:675226095"
              background="white"
              backgroundover="transparent"
              border="white"
              color="black"
              colorover="white"
              padding="5px 1rem"
              css={css`
                margin: 1rem 0;
                @media (min-width: 500px) {
                  margin: 1rem 1rem 1rem 0;
                }
              `}
            >
              <IconPhone width="1.8rem" />
              Reserva Tesla
            </Cta>
          </div>
        </div>
      </section>
      <form>
        <input type="text" name="_gotcha" style={{ display: "none" }} />
        <p>Datos del viaje</p>
        <div className="form-control origen">
          <label htmlFor="origen">Dirección de origen</label>
          <input
            name="origen"
            id="origen"
            placeholder="Dirección donde desea que le recojamos"
            type="text"
          />
        </div>
        <div className="form-control poblacion">
          <label htmlFor="poblacion">Poblacion de recogida</label>
          <select name="poblacion" className="poblacion">
            <option value="">Elija población</option>
            <option value="alacuas">Alacuás</option>
            <option value="albal">Albal</option>
            <option value="albalat">Albalat dels Sorells</option>
            <option value="alboraya">Alboraya</option>
            <option value="albuixech">Albuixech</option>
            <option value="alcacer">Alcácer</option>
            <option value="aldaya">Aldaya</option>
            <option value="alfara">Alfara del Patriarca</option>
            <option value="almacera">Almácera</option>
            <option value="Almusafes">Almusafes</option>
            <option value="benifayo">Benifayó</option>
            <option value="beniparrell">Beniparrell</option>
            <option value="bonrepos">Bonrepós y Mirambell</option>
            <option value="burjasot">Burjasot</option>
            <option value="catarroja">Catarroja</option>
            <option value="chirivella">Chirivella</option>
            <option value="cuart">Cuart de Poblet</option>
            <option value="foyos">Foyos</option>
            <option value="godella">Godella</option>
            <option value="lugar">Lugar Nuevo de la Corona</option>
            <option value="manises">Manises</option>
            <option value="masalfasar">Masalfasar</option>
            <option value="masamagrell">Masamagrell</option>
            <option value="masanasa">Masanasa</option>
            <option value="museros">Museros</option>
            <option value="meliana">Meliana</option>
            <option value="mislata">Mislata</option>
            <option value="moncada">Moncada</option>
            <option value="paiporta">Paiporta</option>
            <option value="paterna">Paterna</option>
            <option value="picanya">Picaña</option>
            <option value="picasent">Picasent</option>
            <option value="puebla">Puebla de Farnáls</option>
            <option value="rafelbunyol">Rafelbuñol</option>
            <option value="rocafort">Rocafort</option>
            <option value="sedavi">Sedaví</option>
            <option value="silla">Silla</option>
            <option value="sollana">Sollana</option>
            <option value="tabernes">Tabernes Blanques</option>
            <option value="torrente">Torrente</option>
            <option value="valencia">VALENCIA</option>
            <option value="vinalesa">Vinalesa</option>
          </select>
        </div>
        <div className="form-control origen">
          <label htmlFor="destino">Dirección de destino</label>
          <input
            name="destino"
            id="destino"
            placeholder="Dirección donde le dejaremos"
            type="text"
          />
        </div>
        <div className="form-control poblacion">
          <label htmlFor="ciudad">Ciudad de destino</label>
          <input
            name="ciudad"
            id="ciudad"
            placeholder="Población, ciudad o País"
            type="text"
          />
        </div>
        <div className="form-control menor">
          <label htmlFor="fecha">Fecha de viaje</label>
          <input name="fecha" id="fecha" placeholder={addDay()} type="date" />
        </div>
        <div className="form-control menor">
          <label htmlFor="hora">Hora de recogida</label>
          <input name="hora" id="hora" placeholder="HH:MM" type="time" />
        </div>
        <div className="form-control menor">
          <label htmlFor="pasajeros">Pasajeros</label>
          <select name="pasajeros" id="pasajeros">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </select>
        </div>
        <p>Datos personales</p>
        <div className="form-control menor">
          <label htmlFor="nombre">Nombre</label>
          <input
            name="nombre"
            id="nombre"
            placeholder="Su nombre y apellidos"
            type="text"
          />
        </div>
        <div className="form-control menor">
          <label htmlFor="telefono">Teléfono</label>
          <input
            name="telefono"
            id="telefono"
            placeholder="Su teléfono móvil o fijo"
            type="text"
          />
        </div>
        <div className="form-control menor">
          <label htmlFor="email">Email</label>
          <input
            name="email"
            id="email"
            placeholder="ejemplo@dominio.es/com"
            type="text"
          />
        </div>
        <div className="form-control textarea">
          <label htmlFor="comentarios">Comentarios</label>
          <textarea
            name="comentarios"
            id="comentarios"
            as="textarea"
            rows="10"
            cols="20"
            placeholder="Cualquier comentario adicional que desee hacernos"
          />
        </div>
        <SubmitWrapper>
          <button type="submit" disabled>
            Enviar datos
          </button>
        </SubmitWrapper>
      </form>
    </FormWrapper>
  )
}

export default BookingForm
