import React from "react"
import Advantages from "./Advantages"
import BookingForm from "./BookingForm"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const Intro = React.lazy(() => import("../sections/Intro"))
const Business = React.lazy(() => import("../sections/Business"))
const BusinessImages = React.lazy(() => import("../sections/BusinessImages"))
const IconsSet = React.lazy(() => import("../sections/IconsSet"))
const Webapp = React.lazy(() => import("../sections/Webapp"))
const Video = React.lazy(() => import("../sections/Video"))
const PageBody = React.lazy(() => import("../sections/PageBody"))

const SectionList = ({ sections }) => {
  const breakpoints = useBreakpoint()
  const isSSR = typeof window === "undefined"
  return Object.keys(sections).map(item =>
    sections[item].name === "advantages" ? (
      <Advantages key={item} content={sections[item].content} />
    ) : sections[item].name === "intro" && !isSSR ? (
      <React.Suspense key={item} fallback={<div>cargando...</div>}>
        <Intro
          content={sections[item].content}
          image={sections[item].image}
          bigScreen={sections[item].bigScreen}
          alt={sections[item].alt}
        />
      </React.Suspense>
    ) : sections[item].name === "business" && !isSSR ? (
      <React.Suspense key={item} fallback={<div>cargando...</div>}>
        <Business
          content={sections[item].content}
          mobileImage={sections[item].mobileBusiness}
          desktopImage={sections[item].desktopBusiness}
          alt={sections[item].alt}
        >
          {breakpoints.desktop && (
            <BusinessImages
              images={sections[item].businessImages}
              altTwo={sections[item].altTwo}
              altThree={sections[item].altThree}
            />
          )}
        </Business>
      </React.Suspense>
    ) : sections[item].name === "iconsset" && !isSSR ? (
      <React.Suspense key={item} fallback={<div>cargando...</div>}>
        <IconsSet content={sections[item].content} />
      </React.Suspense>
    ) : sections[item].name === "webapp" && !isSSR ? (
      <React.Suspense key={item} fallback={<div>cargando...</div>}>
        <Webapp
          image={sections[item].webappImage}
          images={sections[item].webappImages}
          alt={sections[item].alt}
          altTwo={sections[item].altTwo}
          altThree={sections[item].altThree}
          altFour={sections[item].altFour}
          content={sections[item].content}
        />
      </React.Suspense>
    ) : sections[item].name === "video" && !isSSR && !breakpoints.mobile ? (
      <React.Suspense key={item} fallback={<div>cargando...</div>}>
        <Video
          videoMobile={sections[item].videoMovil}
          videoDesktop={sections[item].videoDesktop}
          content={sections[item].content}
        />
      </React.Suspense>
    ) : sections[item].name === "bookingform" ? (
      <BookingForm key={item} content={sections[item].content[0].form} />
    ) : sections[item].name === "pagebody" && !isSSR ? (
      <React.Suspense key={item} fallback={<div>cargando...</div>}>
        <PageBody content={sections[item].content} />
      </React.Suspense>
    ) : null
  )
}

export default SectionList
