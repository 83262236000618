/** @jsx jsx */
import { jsx, css, keyframes } from "@emotion/core"
import React from "react"
import Img from "gatsby-image"

const DesktopImages = ({ images }) => {
  const secondImgAnimation = keyframes`
    0% {
      transform: scale(1.1);
      opacity: 0;
    }
    33% {
      transform: scale(1.1);
      opacity: 0;
    }
    34% {
      transform: scale(1.1);
      opacity: 1;
    }
    66% {
      transform: scale(1);
      opacity: 1;
    }
    67% {
      transform: scale(1);
      opacity: 0;
    }
    100% {
      transform: scale(1.1);
      opacity: 0;
    }
  `
  const thirdImgAnimation = keyframes`
    0% {
      transform: scale(1.1);
      opacity: 0;
    }
    33% {
      transform: scale(1.1);
      opacity: 0;
    }
    34% {
      transform: scale(1.1);
      opacity: 0;
    }
    66% {
      transform: scale(1.1);
      opacity: 0;
    }
    67% {
      transform: scale(1.1);
      opacity: 1;
    }
    99% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  `

  return (
    <React.Fragment>
      <Img
        fluid={images.desktopTwo.childImageSharp.fluid}
        alt="Interior taxi Tesla Model S"
        loading="lazy"
        style={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "0",
          left: "0",
          zIndex: "-2",
        }}
        css={css`
          @media (min-width: 1200px) {
            animation: ${secondImgAnimation} 15s linear infinite;
          }
        `}
      />
      <Img
        fluid={images.desktopThree.childImageSharp.fluid}
        alt="Taxímetro en espejo retrovisor"
        loading="lazy"
        style={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "0",
          left: "0",
          zIndex: "-2",
        }}
        css={css`
          @media (min-width: 1200px) {
            animation: ${thirdImgAnimation} 15s linear infinite;
          }
        `}
      />
    </React.Fragment>
  )
}

export default DesktopImages
