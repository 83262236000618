import styled from "@emotion/styled"
import { css } from "@emotion/core"

const dinamicStyle = props =>
  css`
    color: ${props.color};
  `

const FlexWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 0 1rem;
  ${dinamicStyle};
`

export default FlexWrapper
