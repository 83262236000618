/** @jsx jsx */
import { jsx, css, keyframes } from "@emotion/core"
import Img from "gatsby-image"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import NavDesktop from "../navigation/NavDesktop"
import IconPhone from "../icons/icon-phone"

// Element Styles
import FlexWrapper from "../../elements/FlexWrapper"
import PageHeading from "../../elements/PageHeading"
import HeaderText from "../../elements/HeaderText"
import Cta from "../../elements/Cta"

const HeaderBanner = ({ bannerImg, headerData, children }) => {
  const breakpoints = useBreakpoint()
  const { h1, mainHeading, subHeading, ctaLink, ctaCall } = headerData

  const bannerSources = [
    bannerImg[0].childImageSharp.fluid,
    {
      ...bannerImg[1].childImageSharp.fluid,
      media: `(min-width: 1200px)`,
    },
  ]

  const firstImgAnimation = keyframes`
    0% {
      transform: scale(1);
      opacity: 1;
    }
    33% {
      transform: scale(1.1);
      opacity: 1;
    }
    34% {
      transform: scale(1.1);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  `
  return (
    <header
      css={css`
        position: relative;
        width: 100%;
        height: 70vh;
        z-index: 10;
        background: rgba(0, 0, 0, 1);
        overflow: hidden;
        @media (min-width: 1200px) {
          height: 75vh;
        }
      `}
    >
      {(breakpoints.portrait || breakpoints.desktop) && <NavDesktop />}
      <FlexWrapper color="white">
        {(h1 && <PageHeading margindesktop="3rem">{h1}</PageHeading>) ||
          "Esta página necesita un H1"}
        {mainHeading && <HeaderText>{mainHeading}</HeaderText>}
        {subHeading && (
          <HeaderText className="subheading">{subHeading}</HeaderText>
        )}
        <div
          css={css`
            display: flex;
            flex-flow: column wrap;
            align-content: center;
            width: 100%;
            height: auto;
            @media (min-width: 500px) {
              flex-flow: row nowrap;
              justify-content: center;
            }
          `}
        >
          {ctaCall && (
            <Cta
              title={ctaCall.title}
              href={ctaCall.href}
              background="transparent"
              backgroundover="white"
              border="white"
              color="white"
              colorover="black"
              padding="5px 1rem"
              css={css`
                margin: 1rem 0;
                @media (min-width: 500px) {
                  margin: 1rem 1rem 1rem 0;
                }
              `}
            >
              <IconPhone width="1.8rem" />
              {ctaCall.text}
            </Cta>
          )}
          <Cta
            title={ctaLink.title}
            href={ctaLink.to}
            background="transparent"
            backgroundover="white"
            border="white"
            color="white"
            colorover="black"
            margin="1rem 0"
            padding="5px 1rem"
          >
            {ctaLink.text}
          </Cta>
        </div>
      </FlexWrapper>
      <Img
        fluid={bannerSources}
        alt="Taxi Tesla Valencia"
        loading="eager"
        style={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "0",
          left: "0",
          zIndex: "-2",
        }}
        css={css`
          @media (min-width: 1200px) {
            animation: ${firstImgAnimation} 15s linear infinite;
          }
        `}
      />
      {children}
    </header>
  )
}

export default HeaderBanner
