import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import Cta from "../../elements/Cta"
import IconPhone from "../icons/icon-phone"

const DesktopNavigation = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  &.simple {
    background: black;
    box-shadow: none;
  }
`

const MenuWrapper = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 1rem;
  color: white;
  margin: 0 auto;
  & > a {
    text-decoration: none;
  }
  @media (min-width: 1440px) {
    max-width: 1440px;
  }
  @media (min-width: 1500px) {
    padding: 10px 0;
  }
`

const MenuLinks = styled.ul`
  padding: 0;
  margin: 0;
  flex: 0 1 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1rem;
  & > li {
    padding: 0;
    margin: 0 0 0 2rem;
    list-style: none;
    & > a {
      text-decoration: none;
    }
  }
`

const SiteName = styled.p`
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  & > span {
    font-size: 1rem;
    font-weight: 200;
  }
`

const MavDesktop = ({ simple }) => {
  return (
    <DesktopNavigation className={simple ? "simple" : null}>
      <MenuWrapper>
        <Link to="/" title="Inicio">
          <SiteName>
            VipClass<span>.taxi</span>
          </SiteName>
        </Link>
        <MenuLinks>
          <li>
            <Link to="/" activeClassName="active" title="Página de inicio">
              Inicio
            </Link>
          </li>
          <li>
            <Cta
              title="Reserva un taxi Tesla"
              href="mailto:reservas@vipclass.taxi"
              activeClassName="active"
              background="transparent"
              backgroundover="white"
              border="white"
              color="white"
              colorover="black"
              margin="0"
              padding="2px 1rem"
            >
              reservas@vipclass.taxi
            </Cta>
          </li>
          <li>
            <Cta
              title="Pide un Taxi Tesla"
              href="tel:675226095"
              background="transparent"
              backgroundover="white"
              border="white"
              color="white"
              colorover="black"
              margin="0"
              padding="2px 1rem"
            >
              <IconPhone width="1.5rem" />
              675 226 095
            </Cta>
          </li>
        </MenuLinks>
      </MenuWrapper>
    </DesktopNavigation>
  )
}

export default MavDesktop
