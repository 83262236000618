import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import Layout from "../components/Layout"
import HeaderBanner from "../components/headers/HeaderBanner"
import DesktopImages from "../components/headers/DesktopImages"
import HeaderSimple from "../components/headers/HeaderSimple"
import SectionList from "../components/sections/SectionsImporter"
import SEO from "../components/utils/seo"

export const query = graphql`
  query($slug: String!) {
    pagesJson(slug: { eq: $slug }) {
      title
      description
      robots
      canonical
      slug
      mobileBanner: bannerImage {
        childImageSharp {
          fluid(maxWidth: 600, cropFocus: NORTH) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktopBanner: bannerImage {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1600, cropFocus: NORTH) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bannerDesktopImages {
        desktopTwo: imageTwo {
          childImageSharp {
            fluid(maxWidth: 1600, cropFocus: NORTH) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        desktopThree: imageThree {
          childImageSharp {
            fluid(maxWidth: 1600, cropFocus: NORTH) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      header {
        h1
        mainHeading
        subHeading
        ctaLink {
          text
          title
          to
        }
        ctaCall {
          text
          title
          href
        }
      }
      sections {
        image {
          childImageSharp {
            fixed(width: 600) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        bigScreen: image {
          childImageSharp {
            fixed(width: 900) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        mobileBusiness: businessImage {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        desktopBusiness: businessImage {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        businessImages {
          desktopTwo: imageTwo {
            childImageSharp {
              fixed(width: 960) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          desktopThree: imageThree {
            childImageSharp {
              fixed(width: 960) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        webappImage {
          childImageSharp {
            fixed(width: 300) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
          desktop: childImageSharp {
            fixed(width: 400) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        webappImages {
          imageTwo {
            childImageSharp {
              fixed(width: 300) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
            desktop: childImageSharp {
              fixed(width: 400) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          imageThree {
            childImageSharp {
              fixed(width: 300) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
            desktop: childImageSharp {
              fixed(width: 400) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          imageFour {
            childImageSharp {
              fixed(width: 300) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
            desktop: childImageSharp {
              fixed(width: 400) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        videoMovil {
          publicURL
        }
        videoDesktop {
          publicURL
        }
        alt
        altTwo
        altThree
        altFour
        name
        content {
          body
          description
          h2
          id
          text
          title
          form {
            id
            label
            placeholder
            type
            rows
            cols
          }
          list {
            id
            item
            icono
            title
            movil
            text
          }
          ctaLink {
            text
            to
            title
          }
        }
      }
    }
  }
`

const PageTemplate = ({ data }) => {
  // const isSSR = typeof window === "undefined"
  const breakpoints = useBreakpoint()
  const page = data.pagesJson
  const bannerImg = [page.mobileBanner, page.desktopBanner]

  // if (breakpoints.desktop) {
  //   var DesktopImages = React.lazy(() =>
  //     import("../components/headers/DesktopImages")
  //   )
  // }

  return (
    <Layout>
      <SEO
        title={page.title}
        description={page.description}
        socialImg={page.desktopBanner.publicURL}
        robots={page.robots}
        canonical={page.canonical}
      />
      {page.slug === "/" ? (
        <HeaderBanner bannerImg={bannerImg} headerData={page.header}>
          {breakpoints.desktop && page.bannerDesktopImages && (
            <DesktopImages images={page.bannerDesktopImages} />
          )}
        </HeaderBanner>
      ) : (
        <HeaderSimple headerData={page.header} />
      )}
      <main>{page.sections && <SectionList sections={page.sections} />}</main>
    </Layout>
  )
}
export default PageTemplate
